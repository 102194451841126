import React, { useEffect } from 'react';

const MicrosoftLoginButton = () => {
    const handleLogin = () => {
        console.log("In handleLogin");
        const loginPopup = window.open(
            'https://staging-app.hexvarium.com/api/auth/ms-login',
            'MicrosoftLogin',
            'width=600,height=700'
        );

        if (loginPopup) {
            console.log("in If clause");
            // Add an event listener to receive messages from the popup window
            const messageListener = (event: MessageEvent) => {
                console.log("In message listener", event.data);
                // Confirm the message is from the correct origin and indicates authentication
                if (event.data === 'authenticated') {
                    console.log("In if clause of message listener")
                    window.location.reload();
                    window.removeEventListener('message', messageListener); // Clean up listener after success
                }
            };

            window.addEventListener('message', messageListener);
        } else {
            console.log("in else clause");
            console.error("Popup blocked or failed to open");
        }
    };

    return (
        <button onClick={handleLogin}>
            Login with Microsoft
        </button>
    );
};

export default MicrosoftLoginButton;
