import { useContext, useEffect } from 'react';
import { refreshToken, refreshTokenInit, signout } from '../services/auth.service';
import jwt_decode from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

export function AuthProvider(props: { setSelectedAccountId: React.Dispatch<React.SetStateAction<string>> }) {
   const { isAuthenticated, setAuthenticated } = useContext(AuthContext);
   const navigate = useNavigate();
   const location = useLocation();

   useEffect(() => {
      if (isAuthenticated) {
         refreshTokenInit().then((response) => {
            if (response.status === 403) {
               signout().then(() => {
                  localStorage.removeItem('token');
                  navigate('/login');
               });
            }
         });

         const token = localStorage.getItem('token');
         if (!token) return;
         let decodedToken = jwt_decode(token) as any;

         if (decodedToken.accounts) {
            props.setSelectedAccountId(decodedToken.accounts[0]);
         } else {
            signout().then(() => {
               localStorage.removeItem('token');
               navigate('/login');
            });
         }

         const intervalId = setInterval(async () => {
            const response = await refreshToken();
            if (!response.error) {
               localStorage.setItem('token', response?.token);
            } else {
            }
         }, 300000);

         return () => clearInterval(intervalId);
      } else {
         if (localStorage.getItem('token')) {
            signout().then(() => {
               localStorage.removeItem('token');
               if (location.pathname.includes('create-account')) {
                  console.log('create account', location);
                  navigate(location.pathname + (location.search ?? ''));
               } else {
                  navigate('/login');
               }
            });
         }
         if (location.pathname !== '/login' && !location.pathname.includes('create-account') && location.pathname !== '/auth/ms-login') {
            console.log('not logged in');
            navigate('/login');
         }
      }
   }, [isAuthenticated]);

   useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const isLogout = queryParams.get('logout') === 'true';

      if (isLogout && isAuthenticated) setAuthenticated(false);
   }, [location.search]);

   return null;
}

